import request from './request'

const api = {
	// 登录
	login: '/system/Admin/login',
}

export {
	request,
	api
}
